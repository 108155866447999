import {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import CustomConfirm from "./CustomConfirm";


const PurchasePage = () => {
    const [modal, setModal] = useState(false); // 모달 상태
    const [modalData, setModalData] = useState(null); // 모달에 표시할 데이터
    const [grade, setGrade] = useState("1"); // 초기   등급
    const [showConfirm, setShowConfirm] = useState(false); // confirm 모달 상태
    const [confirmCallback, setConfirmCallback] = useState(null); // confirm 동작 저장
    const navigate = useNavigate();
    useEffect(async () => {
        try {
            const token = localStorage.getItem("jwt"); // 클라이언트에서 토큰 가져오기

            const response = await axios.get("https://weorthersback.shop/users/getuser", {
                headers: {
                    Authorization: `Bearer ${token}`, // 토큰을 Authorization 헤더에 전달
                },

            });
            setGrade(response.data.grade);
        }catch (error) {
            setGrade("1");
        }

    },[])
    const gradeData = [
        {
            grade: "1",
            features: [
                "네이버 순위검색 하루 5건",
                "네이버 스토어 검색 하루 5건",
                "순위추적 기능 제공하지 않음",
            ],
            price: "무료",
            badge: "베이직(무료)",
            style: "bg-green-100 text-green-800", // 1등급 스타일
        },
        {
            grade: "2",
            features: [
                "네이버 순위검색 하루 10건",
                "네이버 스토어 검색 하루 10건",
                "순위추적 기능 제공하지 않음",
            ],
            price: "100원",
            badge: "스탠다드",
            style: "bg-blue-100 text-blue-800", // 2등급 스타일
        },
        {
            grade: "3",
            features: [
                "네이버 순위검색 하루 20건",
                "네이버 스토어 검색 하루 20건",
                "순위추적 기능 제공 통합 10건",
            ],
            price: "200원",
            badge: "프로",
            style: "bg-yellow-100 text-yellow-800", // 3등급 스타일
        },
        {
            grade: "4",
            features: [
                "네이버 순위검색 하루 30건",
                "네이버 스토어 검색 하루 30건",
                "순위추적 기능 제공 통합 10건",
            ],
            price: "300원",
            badge: "프리미엄",
            style: "bg-orange-100 text-orange-800", // 4등급 스타일
        },
        {
            grade: "5",
            features: [
                "네이버 순위검색 하루 50건",
                "네이버 스토어 검색 하루 50건",
                "순위추적 기능 제공 통합 10건",
            ],
            price: "400원",
            badge: "마스터",
            style: "bg-red-100 text-red-800", // 5등급 스타일
        },
    ];

    // 현재 등급의 데이터 가져오기
    const currentGrade = gradeData.find((item) => item.grade === grade);

    // 모달을 띄울 데이터 설정
    const openModal = async (orderId, orderName, amount) => {
        try {
            // 사용자의 정보 가져오기 (토큰 사용)
            const token = localStorage.getItem("jwt"); // 클라이언트에서 토큰 가져오기
            if (!token) {
                alert("회원권 구매는 로그인 후 가능합니다.");
                return;
            }

            const response = await axios.get("https://weorthersback.shop/users/getuser", {
                headers: {
                    Authorization: `Bearer ${token}`, // 토큰을 Authorization 헤더에 전달
                },
            });

            const userDTO = response.data; // 서버에서 받아온 사용자 정보
            console.log("User Data: ", userDTO);

            // 등급 확인 및 경고 메시지
            if (parseInt(grade) > 1) {
                setShowConfirm(true); // 커스텀 Confirm 모달 열기
                setConfirmCallback(() => () => {
                    setModal(true); // 모달 활성화
                    setShowConfirm(false); // Confirm 닫기
                });
            } else {
                setModal(true); // 바로 모달 활성화
            }

            // 모달 데이터 업데이트
            setModalData({
                orderId,
                orderName,
                amount,
                user: userDTO, // 사용자 데이터를 포함
            });
            // setModalData({
            //     orderId,
            //     orderName,
            //     amount,
            //     user: {
            //         userName: "알 수 없음",
            //         userEmail: "정보 없음",
            //         userPhone: "정보 없음",
            //     },
            // });
            // 모달 활성화
            setModal(true);
        } catch (error) {
            console.error("사용자 정보를 가져오는 데 실패했습니다:", error);

            // 사용자 정보를 가져오지 못했을 경우 기본 데이터를 설정
            setModalData({
                orderId,
                orderName,
                amount,
                user: {
                    userName: "알 수 없음",
                    userEmail: "정보 없음",
                    userPhone: "정보 없음",
                },
            });
            setModal(true);
        }
    };

    // CheckoutPage로 이동
    const proceedToCheckout = () => {
        if (modalData) {
            console.log("modaldata: ", modalData);

            // 로컬스토리지에 데이터 저장
            localStorage.setItem("checkoutData", JSON.stringify(modalData));

            // /checkout으로 리다이렉트
            navigate("/checkout");

            // 리다이렉트 후 새로고침
            setTimeout(() => {
                window.location.reload();
            }, 100); // navigate가 완료된 후 실행되도록 약간의 딜레이 추가
        }
    };

    return (
        <div>
            {/* 모달 */}
            {modal && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div
                        className="max-w-lg w-full mx-auto bg-white shadow-md rounded-lg border border-gray-200 overflow-hidden"
                    >
                        <div className="bg-gradient-to-r from-green-400 to-blue-500 text-white text-center py-6">
                            <h1 className="text-3xl font-bold">결제 정보</h1>
                            <p className="text-sm mt-2">아래 정보를 확인 후 결제를 진행하세요.</p>
                        </div>
                        <div className="p-6 space-y-4 text-gray-700">
                            {/* 상품명 */}
                            <div>
                                <p className="font-semibold text-lg">상품명</p>
                                <p className="text-2xl font-bold text-gray-900">
                                    {modalData?.orderName || "상품 정보 없음"}
                                </p>
                            </div>
                            {/* 결제 금액 */}
                            <div>
                                <p className="font-semibold text-lg">결제 금액</p>
                                <p className="text-3xl font-bold text-green-600">
                                    {modalData?.amount?.value?.toLocaleString()} {modalData?.amount?.currency || "KRW"}
                                </p>
                            </div>
                            {/* 주문 번호 */}
                            <div>
                                <p className="font-semibold text-lg">주문 번호</p>
                                <p className="text-gray-600 text-sm">{modalData?.orderId}</p>
                            </div>
                            <div>
                                <p className="font-semibold text-lg">주문자</p>
                                <p className="text-gray-600 text-sm">{modalData?.user.userName}</p>
                            </div>
                            <div>
                                <p className="font-semibold text-lg">전화 번호</p>
                                <p className="text-gray-600 text-sm">{modalData?.user.userPhone}</p>
                            </div>
                            <div>
                                <p className="font-semibold text-lg">이메일</p>
                                <p className="text-gray-600 text-sm">{modalData?.user.userEmail}</p>
                            </div>
                        </div>
                        <div className="bg-gray-100 px-6 py-4 flex justify-center gap-4">
                            <button
                                className="py-3 px-4 bg-gray-300 text-gray-700 font-bold text-lg rounded hover:opacity-90 transition"
                                onClick={() => setModal(false)} // 모달 닫기
                            >
                                취소
                            </button>
                            <button
                                className="py-3 px-4 bg-gradient-to-r from-green-400 to-blue-500 text-white font-bold text-lg rounded hover:shadow-lg hover:opacity-90 transition"
                                onClick={proceedToCheckout} // CheckoutPage로 이동
                            >
                                결제 진행하기
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {showConfirm && (
                <CustomConfirm
                    message="현재 스탠다드 등급 이상입니다. 구독권 구매 시 이전에 구매한 구독권은 소멸합니다. 구매를 진행하시겠습니까?"
                    onConfirm={() => confirmCallback()}
                    onCancel={() => setShowConfirm(false)}
                />
            )}
            {/* 현재 등급 표시 */}
            <div className="flex flex-col items-center py-12">
                {
                    grade === "6" ?
                        <p
                            className="px-4 py-2 rounded-full font-semibold text-2xl bg-black text-white"

                        >
                            회원님의 등급은 "관리자" 입니다.
                        </p>
                        :
                        <p
                            className={`inline-block px-4 py-2 rounded-full font-semibold text-2xl ${
                                currentGrade?.style || "bg-gray-100 text-gray-800"
                            }`}
                        >
                            회원님의 등급은 {currentGrade?.badge}입니다.
                        </p>
                }

                <p className="text-center text-gray-700 mt-4 text-lg pt-6">
                    지금 바로 회원권을 구입하고 확 늘어난 검색 횟수 및 랭킹 분석 기능으로 비즈니스의 성장을 경험하세요!
                    <span className="text-red-500 font-bold"> 30일 동안의 특별한 혜택</span>을 놓치지 마세요.
                </p>
                <p className="text-center text-gray-700 mt-4 text-lg pt-2">
                    새로 회원가입하셨나요? 위아더스 카카오 채널을 추가하고 무료로 한달간
                    <span className="text-red-500 font-bold"> 프로 등급</span>의 혜택을 받아 보세요.
                </p>
            </div>

            {/* 등급 카드 */}
            <div className="flex justify-center pb-5">
                <div className="w-full max-w-7xl px-4">
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-6">
                        {gradeData.map((item, index) => (
                            <div
                                key={index}
                                className="border border-gray-200 rounded-lg shadow-sm hover:shadow-lg transition-shadow bg-white text-center p-6"
                            >
                                {/* Badge */}
                                <div className="flex justify-center mb-4">
                                    <span
                                        className={`text-white text-sm font-semibold px-3 py-1 rounded-full ${
                                            index === 0
                                                ? "bg-green-500"
                                                : index === 1
                                                    ? "bg-blue-500"
                                                    : index === 2
                                                        ? "bg-yellow-500"
                                                        : index === 3
                                                            ? "bg-orange-500"
                                                            : "bg-red-500"
                                        }`}
                                    >
                                        {item.badge}
                                    </span>
                                </div>

                                {/* Price */}
                                <p className="text-lg font-bold text-gray-800 mb-4">
                                    가격: {item.price}
                                </p>

                                {/* Stars (등급에 맞는 별 개수) */}
                                <div className="flex justify-center mb-4">
                                    {Array.from({ length: parseInt(item.grade) }).map((_, i) => (
                                        <svg
                                            key={i}
                                            xmlns="http://www.w3.org/2000/svg"
                                            height="20px"
                                            viewBox="0 -960 960 960"
                                            width="20px"
                                            fill="#FFD700"
                                            className="mx-1"
                                        >
                                            <path
                                                d="m354-287 126-76 126 77-33-144 111-96-146-13-58-136-58 135-146 13 111 97-33 143ZM233-120l65-281L80-590l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Zm247-350Z"
                                            />
                                        </svg>
                                    ))}
                                </div>

                                {/* Features */}
                                <ul className="text-gray-600 text-sm space-y-2 mb-6">
                                    {item.features.map((feature, i) => (
                                        <li key={i} className="">
                                            {feature}
                                        </li>
                                    ))}
                                </ul>

                                {/* Purchase Button */}
                                {
                                    parseInt(grade) < parseInt(item.grade) ? ( // 문자열 숫자를 숫자로 변환한 뒤 비교
                                        <button
                                            className="w-full py-2 rounded-full bg-gray-200 hover:bg-gray-300"
                                            onClick={() => {
                                                // 모달 열기
                                                const orderId = uuidv4();
                                                openModal(orderId, item.badge, {
                                                    currency: "KRW",
                                                    value: parseInt(item.price.replace("원", "")) || 0,
                                                });
                                            }}
                                        >
                                            회원권 구매하기
                                        </button>
                                    ) : null
                                }

                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PurchasePage;