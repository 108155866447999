import React from "react";

const Footer = () => {
    return (
        <footer className="bg-gray-800 text-white py-6" >
            <div className="container mx-auto px-4">
                {/* 상단: 회사 정보 및 링크 */}
                <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                    <div>
                        <h5 className="font-bold mb-2">회사 정보</h5>
                        <ul>
                            <li>대표자명: 이형동</li>
                            <li>사업자번호: 824-81-03528</li>
                            <li>전화번호: 010-6635-5578</li>
                            <li>주소: 인천광역시 연수구 센트럴로 313 </li>

                        </ul>
                    </div>
                    <div>
                        <h5 className="font-bold mb-2">서비스</h5>
                        <ul>
                            {/*<li>*/}
                            {/*    <a href="/about" className="hover:underline">*/}
                            {/*        회사 소개*/}
                            {/*    </a>*/}
                            {/*</li>*/}
                            <li>
                                <a href="/terms" className="hover:underline">
                                    이용약관
                                </a>
                            </li>
                            <li>
                                <a href="/privacy" className="hover:underline">
                                    개인정보 처리방침
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <h5 className="font-bold mb-2">소셜 미디어</h5>
                        <ul className="flex-col space-x-4 text-center">
                            <li>
                                <a
                                    href="https://weothers.tistory.com/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="hover:underline"
                                >
                                    티스토리
                                </a>
                            </li>
                            {/*<li>*/}
                            {/*    <a*/}
                            {/*        href="https://twitter.com"*/}
                            {/*        target="_blank"*/}
                            {/*        rel="noopener noreferrer"*/}
                            {/*        className="hover:underline"*/}
                            {/*    >*/}
                            {/*        트위터*/}
                            {/*    </a>*/}
                            {/*</li>*/}
                            {/*<li>*/}
                            {/*    <a*/}
                            {/*        href="https://instagram.com"*/}
                            {/*        target="_blank"*/}
                            {/*        rel="noopener noreferrer"*/}
                            {/*        className="hover:underline"*/}
                            {/*    >*/}
                            {/*        인스타그램*/}
                            {/*    </a>*/}
                            {/*</li>*/}
                        </ul>
                    </div>
                </div>

                {/* 하단: 저작권 정보 */}
                <div className="border-t border-gray-700 mt-6 pt-4 text-center text-sm">
                    &copy; 2024 WeOthers Co., Ltd. All Rights Reserved.
                </div>
            </div>
        </footer>
    );
};

export default Footer;
