import React from "react";

const CustomConfirm = ({ message, onConfirm, onCancel }) => {
    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white p-6 rounded-lg shadow-md text-center max-w-sm">
                <p className="text-gray-700 text-lg">{message}</p>
                <div className="mt-4 flex justify-center gap-4">
                    <button
                        onClick={onCancel}
                        className="bg-gray-300 hover:bg-gray-400 text-gray-800 py-2 px-4 rounded"
                    >
                        취소
                    </button>
                    <button
                        onClick={onConfirm}
                        className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded"
                    >
                        확인
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CustomConfirm;