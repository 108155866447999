import { loadTossPayments } from "@tosspayments/tosspayments-sdk";
import { useEffect, useState } from "react";


const clientKey = "test_gck_docs_Ovk5rk1EwkEbP0W43n07xlzm";

export function CheckoutPage() {
    // 로컬 스토리지에서 checkout 데이터 가져오기
    const [checkoutData, setCheckoutData] = useState(() => {
        const savedData = localStorage.getItem("checkoutData");
        return savedData ? JSON.parse(savedData) : null;
    });

    // 데이터가 없을 경우 처리 (리다이렉트나 에러 처리 로직 추가)
    useEffect(() => {
        if (!checkoutData) {
            console.error("유효하지 않은 결제 정보입니다.");
            window.location.href = "/"; // 홈 또는 이전 페이지로 리다이렉트
        }
    }, [checkoutData]);

    const { orderId, orderName, amount, user } = checkoutData || {}; // 데이터를 안전하게 구조 분해
    console.log(
        "orderId: ",
        orderId,
        "orderName: ",
        orderName,
        "amount: ",
        amount.value,
        "user: ",
        user.userName)


    const [ready, setReady] = useState(false);
    const [widgets, setWidgets] = useState(null);

    useEffect(() => {
        async function fetchPaymentWidgets() {
            if (!orderId || !amount) {
                console.error("유효하지 않은 결제 정보입니다.");
                return;
            }

            const tossPayments = await loadTossPayments(clientKey);
            const widgets = tossPayments.widgets({
                customerKey: "9YtdRmqUTF-0bZRLFaTS6",
            });
            setWidgets(widgets);
        }

        fetchPaymentWidgets();
    }, [orderId, amount]);

    useEffect(() => {
        async function renderWidgets() {
            if (!widgets) return;

            await widgets.setAmount(amount); // 전달받은 금액으로 설정

            await Promise.all([
                widgets.renderPaymentMethods({
                    selector: "#payment-method",
                    variantKey: "DEFAULT",
                }),
                widgets.renderAgreement({
                    selector: "#agreement",
                    variantKey: "AGREEMENT",
                }),
            ]);

            setReady(true);
        }

        renderWidgets();
    }, [widgets]);

    return (
        <div className="wrapper">

            <div id="payment-method"></div>
            <div id="agreement"></div>
            <button
                className="button p-2 bg-blue-500 text-white rounded-md"
                disabled={!ready}
                onClick={async () => {
                    try {
                        await widgets.requestPayment({
                            orderId,
                            orderName,
                            successUrl: "https://위아더스.shop" + "/success",
                            failUrl: "https://위아더스.shop" + "/fail",
                        });
                    } catch (error) {
                        console.error(error);
                    }
                }}
            >
                결제하기
            </button>
        </div>
    );
}