import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const SearchBarWithTypingEffect = () => {
    const rotateContent = ["화환", "사탕", "장화", "강아지옷", "볼펜"]; // 타이핑 효과 내용
    const [rotateText, setRotateText] = useState("");
    const [keyword, setKeyword] = useState(""); // 검색 키워드 상태
    const navigate = useNavigate();

    // 타이핑 효과 로직
    useEffect(() => {
        let textIndex = 0;

        const typeText = () => {
            setRotateText(rotateContent[textIndex]);
            textIndex = (textIndex + 1) % rotateContent.length; // 순환
        };

        typeText(); // 초기 텍스트 설정
        const typingInterval = setInterval(typeText, 3000); // 3초 간격으로 텍스트 변경

        return () => clearInterval(typingInterval); // 언마운트 시 정리
    }, []);

    // 검색 버튼 클릭 핸들러
    const handleSearch = () => {
        const token = localStorage.getItem("jwt"); // JWT 토큰 확인
        if (!token) {
            alert("검색 시 로그인이 필요합니다");
            navigate("/login"); // 로그인 페이지로 이동
        } else if (keyword.trim() === "") {
            alert("검색어를 입력해주세요!");
        } else {
            navigate("/search", { state: { keyword } }); // Home으로 키워드 전달
        }
    };

    // 엔터키 입력 감지
    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            handleSearch(); // 엔터 입력 시 검색 실행
        }
    };

    return (
        <div className="flex justify-center space-x-0 pt-6 relative">
            <input
                name="search"
                value={keyword} // 입력값 바인딩
                onChange={(e) => setKeyword(e.target.value)} // 입력값 업데이트
                onKeyDown={handleKeyDown} // 엔터키 입력 이벤트
                className="pl-12 border border-gray-400 rounded-l-lg w-[620px] h-[65px] text-lg"
                placeholder={rotateText || "검색 키워드를 입력해주세요"} // 타이핑 효과 적용
            />

            <button
                onClick={handleSearch} // 버튼 클릭 시 검색 실행
                className="bg-blue-500 rounded-r-md text-white p-2 w-[120px] h-[65px]"
            >
                검색
            </button>
        </div>
    );
};

export default SearchBarWithTypingEffect;