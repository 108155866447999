import {useEffect, useState} from "react";
import { useMediaQuery } from "react-responsive";
import SearchCount from "./SearchCount";

const Searchbar = ({ setKeyword, setStore, islogin, keyword,data, store, setIslogin }) => {

    const isDesktop = useMediaQuery({ query: "(min-width: 1224px)" });
    const isMobile = useMediaQuery({ query: "(max-width: 1223px)" });
    const [inputs, setInputs] = useState({ keyword: "", store: "" });
    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputs((prev) => ({ ...prev, [name]: value }));
    };

    const handleSearch = () => {
        if (!inputs.keyword.trim()) {
            alert("검색어를 입력해주세요!");
            return;
        }
        setKeyword(inputs.keyword);
        setStore(inputs.store);
    };

    const handleKeyDown = (e) => e.key === "Enter" && handleSearch();

    useEffect(() => {
        // keyword가 바뀔 때마다 이 안의 코드가 실행됩니다.
        console.log('Keyword changed:', keyword);
    }, [keyword]); // 의존성 배열에 keyword 추가

    return (
        <div>
            {isDesktop && (
                <div>
                    <div className="flex justify-center pt-[30px]">
                        <div className="sm:content-center relative">
                            <p className="text-[48px] py-3 font-bold">키워드 지수분석</p>
                            <span className="text-[20px] text-gray-500">검색 키워드로 현재 판매중인 </span>
                            <span className="text-[20px] text-yellow-400">상품지수</span>
                            <span className="text-[20px] text-gray-600">가 궁금하다면 지금 분석 해보세요</span>
                            <p className="text-gray-400 text-[15px]">
                                ※ 네이버 쇼핑 기준 100위까지 분석 가능하며 100위 초과 상품은 분석 불가합니다.
                            </p>
                        </div>
                    </div>
                    {isDesktop && islogin ? (
                        <div className="pt-6 mr-[20%]">
                            <SearchCount keyword={keyword} data={data} nvmid={store} setIslogin={setIslogin}/>
                        </div>
                    ) : null}
                    <div className="flex justify-center space-x-0 pt-6 relative">
                        <input
                            name="keyword"
                            className="pl-12 border-l border-t border-b border-gray-400 rounded-l-lg w-[300px] h-[65px] text-lg"
                            value={inputs.keyword}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            placeholder="검색어를 입력하세요"
                        />
                        <input
                            name="store"
                            className="pl-12 border-l border-t border-b border-gray-400 w-[300px] h-[65px] text-lg"
                            value={inputs.store}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            placeholder="상품명/스토어명을 입력하세요"
                        />
                        <button
                            className="bg-blue-500 rounded-r-md text-white p-2 w-[120px] h-[65px]"
                            onClick={handleSearch}
                        >
                            검색
                        </button>
                    </div>
                </div>
            )}

            {isMobile && (
                <div>
                    <div className="flex justify-center pt-[24px] relative w-[90%] pl-[15%]">
                        <div className="sm:content-center relative">
                            <p className="text-[28px] py-3 font-bold">키워드 지수분석</p>
                            <span className="text-[15px] text-gray-500">검색 키워드로 현재 판매중인 </span>
                            <span className="text-[15px] text-yellow-400">상품지수</span>
                            <span className="text-[15px] text-gray-600">가 궁금하다면 지금 분석 해보세요</span>
                            <p className="text-gray-400 text-[12px]">
                                ※ 네이버 쇼핑 기준 100위까지 분석 가능하며 100위 초과 상품은 분석 불가합니다.
                            </p>
                        </div>
                    </div>
                    <div className="flex justify-center space-x-0 pt-8 relative">
                        <input
                            name="keyword"
                            className="pl-12 border-l border-t border-b border-gray-400 rounded-l-lg w-[80%] h-auto"
                            value={inputs.keyword}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            placeholder="검색어를 입력하세요"
                        />
                        <button
                            className="bg-blue-500 rounded-r-md text-white p-2 w-auto h-auto"
                            onClick={handleSearch}
                        >
                            검색
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Searchbar;
