import {useEffect, useState} from "react";
import Loading from "./Loading";
import KakaoLogoutButton from "./KakaoLogout";
import {useNavigate} from "react-router-dom";
import axios from "axios";

const Mypage = ({setIslogin}) => {
    const [loading, setLoading] = useState(false);
    const [dateLimit, setDateLimit] = useState(null);
    const [userInfo, setUserInfo] = useState({
        userId: "",
        userName: "",
        userEmail: "",
        userPhone: "",
        userAddress: "",
        userDetailaddress: "",
        regdate: "",
        grade: "",
    });
    const [grade, setGrade] = useState("1");

    const gradeData = [
        {
            grade: "1",
            features: [
                "네이버 순위검색 하루 5건",
                "네이버 스토어 검색 하루 5건",
                "순위추적 기능 제공하지 않음",
            ],
            price: "무료",
            badge: "베이직(무료)",
            style: "bg-green-100 text-green-800",
        },
        {
            grade: "2",
            features: [
                "네이버 순위검색 하루 10건",
                "네이버 스토어 검색 하루 10건",
                "순위추적 기능 제공하지 않음",
            ],
            price: "100원",
            badge: "스탠다드",
            style: "bg-blue-100 text-blue-800",
        },
        {
            grade: "3",
            features: [
                "네이버 순위검색 하루 20건",
                "네이버 스토어 검색 하루 20건",
                "순위추적 기능 제공 통합 10건",
            ],
            price: "200원",
            badge: "프로",
            style: "bg-yellow-100 text-yellow-800",
        },
        {
            grade: "4",
            features: [
                "네이버 순위검색 하루 30건",
                "네이버 스토어 검색 하루 30건",
                "순위추적 기능 제공 통합 10건",
            ],
            price: "300원",
            badge: "프리미엄",
            style: "bg-orange-100 text-orange-800",
        },
        {
            grade: "5",
            features: [
                "네이버 순위검색 하루 50건",
                "네이버 스토어 검색 하루 50건",
                "순위추적 기능 제공 통합 10건",
            ],
            price: "400원",
            badge: "마스터",
            style: "bg-red-100 text-red-800",
        },
    ];

    const formatDate = (localDateTime) => {
        if (!localDateTime) return "날짜 정보 없음"; // 날짜 정보가 없는 경우
        const formattedDate = localDateTime.split(".")[0].replace(" ", "T"); // 공백을 "T"로 변환
        const date = new Date(formattedDate);
        if (isNaN(date.getTime())) return "유효하지 않은 날짜"; // 유효하지 않은 날짜 처리
        return date.toISOString().split("T")[0]; // YYYY-MM-DD 형식으로 반환
    };

    const navigate = useNavigate();

    const GoLogout = () => {
        setIslogin(false);
        localStorage.clear();
        navigate("/login");
    };

    useEffect(() => {
        setLoading(true);

        const jwtToken = localStorage.getItem("jwt");

        if (jwtToken) {
            fetch("https://weorthersback.shop/users/getuser", {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${jwtToken}`,
                },
            })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error("Failed to fetch user data");
                    }
                    return response.json();
                })
                .then((data) => {
                    console.log("유저 정보:", data);

                    const updatedUserInfo = {
                        ...userInfo,
                        ...data, // 기존 상태와 병합
                        regdate: data.regdate || "", // 날짜 초기화
                        grade: data.grade || "1", // 등급 기본값 제공
                    };

                    setUserInfo(updatedUserInfo); // 유저 정보 상태 업데이트
                    setGrade(data.grade || "1"); // 등급 설정 및 기본값 제공

                    // 추가로 axios로 localhost:8080/datelimit 요청 전송
                    if (updatedUserInfo.userIdx) {
                        axios
                            .get(`https://weorthersback.shop/datelimit/${updatedUserInfo.userIdx}`)
                            .then((response) => {
                                if (response.status === 200) {
                                    // 데이터 형태 확인
                                    console.log("API 응답 데이터:", response.data);

                                    // response.data 자체가 remainingDays일 가능성이 있음
                                    const remainingDays =
                                        response.data?.remainingDays !== undefined
                                            ? response.data.remainingDays
                                            : response.data;

                                    console.log(`남은 유효기간: ${remainingDays}일`);

                                    // 상태로 저장
                                    setDateLimit(remainingDays);
                                } else {
                                    console.error("날짜 제한 정보 요청 실패:", response.status);
                                }
                            })
                            .catch((error) => {
                                console.error("날짜 제한 API 호출 에러:", error);
                            });
                    } else {
                        console.error("userIdx가 없습니다.");
                    }

                    setLoading(false);
                })
                .catch((error) => {
                    console.error("Error:", error);
                    setLoading(false);
                });
        } else {
            alert("JWT 토큰이 없습니다");
            setLoading(false);
        }
    }, []);

    const currentGrade = gradeData.find((item) => item.grade === grade);

    return (
        <div>
            {loading ? <Loading/> : null}

            <div className="flex min-h-screen items-start justify-center bg-gray-100 py-12">
                <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-2xl">
                    <div className="pb-7">
                        {userInfo.grade === "6" ? (
                            <p className="px-4 py-2 rounded-full font-semibold text-2xl bg-black text-white">
                                회원님의 등급은 "관리자" 입니다.
                            </p>

                        ) : (
                            <div>
                                <p
                                    className={`inline-block px-4 py-2 rounded-full font-semibold text-2xl ${
                                        currentGrade?.style || "bg-gray-100 text-gray-800"
                                    }`}
                                >
                                    회원님의 등급은 {currentGrade?.badge || "정보 없음"}입니다.
                                </p>
                                {
                                    dateLimit !== -1 ?
                                        <p>구독권의 남은 기간 : {dateLimit} 일 남음.</p>
                                        :null
                                }

                            </div>

                        )}
                    </div>
                    <div className="text-left">
                        <h1 className="text-4xl font-bold text-blue-600 ml-2 ">마이페이지</h1>
                        <p className="text-gray-600 mt-4 ml-2">환영합니다! 정보를 확인하거나 변경하세요.</p>
                    </div>

                    <div className="mt-6">
                        <div className="border rounded-lg p-6 bg-gray-50">
                            {userInfo ? (
                                <>
                                    <div className="flex items-center justify-between mb-4">
                                        <span className="text-gray-600">이름:</span>
                                        <span className="text-gray-900 font-medium">
                                          {userInfo.userName || "이름 없음"}
                                        </span>
                                    </div>
                                    <div className="flex items-center justify-between mb-4">
                                        <span className="text-gray-600">ID:</span>
                                        <span className="text-gray-900 font-medium">
                                          {userInfo.userId || "정보 없음"}
                                        </span>
                                    </div>
                                    <div className="flex items-center justify-between mb-4">
                                        <span className="text-gray-600">이메일:</span>
                                        <span className="text-gray-900 font-medium">
                                          {userInfo.userEmail || "정보 없음"}
                                        </span>
                                    </div>
                                    <div className="flex items-center justify-between mb-4">
                                        <span className="text-gray-600">전화번호:</span>
                                        <span className="text-gray-900 font-medium">
                                          {userInfo.userPhone || "정보 없음"}
                                        </span>
                                    </div>
                                    <div className="flex items-center justify-between mb-4">
                                        <span className="text-gray-600">주소:</span>
                                        <span className="text-gray-900 font-medium">
                                          {userInfo.userAddress || "정보 없음"}
                                        </span>
                                    </div>
                                    <div className="flex items-center justify-between mb-4">
                                        <span className="text-gray-600">상세주소:</span>
                                        <span className="text-gray-900 font-medium">
                                          {userInfo.userDetailaddress || "정보 없음"}
                                        </span>
                                    </div>
                                    <div className="flex items-center justify-between mb-4">
                                        <span className="text-gray-600">가입일:</span>
                                        <span className="text-gray-900 font-medium">
                                          {formatDate(userInfo.regdate)}
                                        </span>
                                    </div>
                                    <div className="flex items-center justify-between">
                                        <span className="text-gray-600">등급:</span>
                                        <span className="text-green-600 font-medium">
                                          {userInfo.grade === "1"
                                              ? "베이직 등급"
                                              : userInfo.grade === "2"
                                                  ? "스탠다드 등급"
                                                  : userInfo.grade === "5"
                                                      ? "마스터 등급"
                                                      : userInfo.grade}
                                        </span>
                                    </div>
                                </>
                            ) : (
                                <p className="text-gray-600">사용자 정보가 없습니다.</p>
                            )}
                        </div>
                    </div>
                    <div className="mt-8 flex gap-4">
                        <button
                            className="flex-1 py-2 px-4 bg-blue-600 text-white rounded-lg shadow-md hover:bg-blue-700 transition duration-300"
                            onClick={() => {
                                navigate("/userupdate");
                            }}
                        >
                            정보 수정
                        </button>
                        <div
                            className="flex-1 py-2 px-4 bg-gray-200 text-gray-700 rounded-lg shadow-md hover:bg-gray-300 transition duration-300 hover:cursor-pointer"
                            onClick={() => {
                                GoLogout();
                            }}
                        >
                            <KakaoLogoutButton/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Mypage;