import logo from './logo.svg';
import './App.css';
import Navbar from "./Components/Navbar";
import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import Home from "./Components/Home";
import {useEffect, useState} from "react";
import SignPage from "./Components/SignupPage";
import LoginPage from "./Components/LoginPage";
import NaverSearch from "./Components/NaverSearch";
import NaverSearch2 from "./Components/NaverSearch2";
import {useMediaQuery} from "react-responsive";
import Navbar1 from "./Components/Navbar1";
import SignupPage from "./Components/SignupPage";
import Test1 from "./Components/NowSearch";
import OAuth from "./Components/OAuth";
import Mypage from "./Components/Mypage";
import KakaoLogin3 from "./Components/KakaoLogin3";
import SearchCount from "./Components/SearchCount";
import UserUpdate from "./Components/UserUpdate";
import Adminpage from "./Components/Adminpage";
import OAuth2 from "./Components/OAuth2";
import Footer from "./Components/Footer";
import Agreement from "./Components/Agreement";
import Agreement2 from "./Components/Agreement2";
import Agreement3 from "./Components/Agreement3";
import NowSearch from "./Components/NowSearch";
import NowSearch2 from "./Components/NowSearch2";
import TestPage from "./Components/TestPage";
import RankTrace from "./Components/RankTrace";
import Callcenter from "./Components/Callcenter";
import Callcenter2 from "./Components/Callcenter2";
import 'react-quill/dist/quill.snow.css';
import CallcenterDetail from "./Components/CallcenterDetail";
import Mycall from "./Components/Mycall";
import Notice from "./Components/Notice";
import NoticeWrite from "./Components/NoticeWrite";
import NoticeDetail from "./Components/NoticeDetail";
import RankTraceDetail from "./Components/RankTraceDetail";
import HomePage from "./Components/HomePage";
import PurchasePage from "./Components/PurchasePage";
import {SuccessPage} from "./Components/SuccessPage";
import {FailPage} from "./Components/FailPage";
import PurchasePage2 from "./Components/PurchasePage2";
import {CheckoutPage} from "./Components/CheckoutPage"; // 기본 스타일


function App() {
    const [islogin, setIslogin] = useState(false);
    const [navType, setNavType] = useState('A');
    const isDesktop = useMediaQuery({ query: "(min-width: 1224px)" });
    const [userinfo, setUserinfo] = useState(null);
    const navigate = useNavigate();
    const [admin, setAdmin] = useState(false);

    // 현재 경로 가져오기
    const location = useLocation(); // 현재 URL 경로 확인

    useEffect(() => {
        const token = localStorage.getItem("jwt");
        if (token) {
            setIslogin(true);
        } else {
            setIslogin(false);
        }
    }, []);

    useEffect(() => {
        const admin = localStorage.getItem("admin");
        if (admin === "true") {
            setAdmin(true);
        } else {
            setAdmin(false);
        }
    }, []);

    return (
        <div className="App flex flex-col min-h-[111vh]">
            {/* 헤더 */}
            {isDesktop && (
                <Navbar
                    navType={navType}
                    setNavType={setNavType}
                    islogin={islogin}
                    setIslogin={setIslogin}
                    admin={admin}
                    sticky={location.pathname === "/"} // URL이 '/'일 때만 sticky 추가
                />
            )}

            {/* 메인 콘텐츠 */}
            <main className="flex-grow w-full mx-auto ">

                <Routes>
                    <Route path='/' element={<HomePage/>}/>
                    <Route path='/search' element={<Home navType={navType} setNavType={setNavType} islogin={islogin} setIslogin={setIslogin}/>}/>
                    <Route path='/naversearch1' element={<NaverSearch navType={navType} setNavType={setNavType}/>}/>
                    <Route path='/naversearch2' element={<NaverSearch2 navType={navType} setNavType={setNavType}/>}/>
                    <Route path='/signup' element={<SignupPage userinfo={userinfo} setUserinfo={setUserinfo}/>}/>
                    <Route path='/login' element={<LoginPage setIslogin={setIslogin}/>}/>
                    <Route path="/oauth/kakao"
                           element={<OAuth userinfo={userinfo} setUserinfo={setUserinfo} setAdmin={setAdmin}/>}/>
                    <Route path="/oauth/userinfo"
                           element={<OAuth2 userinfo={userinfo} setUserinfo={setUserinfo} setAdmin={setAdmin}/>}/>
                    <Route path="/mypage" element={<Mypage setIslogin={setIslogin}/>}/>
                    <Route path="/userupdate" element={<UserUpdate setIslogin={setIslogin}/>}/>
                    <Route path="/testpage" element={<TestPage/>}/>

                    <Route path="/adminpage" element={<Adminpage admin={admin}/>}/>
                    <Route path="/terms" element={<Agreement2/>}/>
                    <Route path="/privacy" element={<Agreement3/>}/>
                    <Route path="/searchnow" element={<NowSearch islogin={islogin} setIslogin={setIslogin}/>}/>
                    <Route path="/searchnow2" element={<NowSearch2 islogin={islogin} setIslogin={setIslogin}/>}/>
                    <Route path="/admin/callcenter" element={<Callcenter></Callcenter>}></Route>
                    <Route path="/callcenter/register" element={<Callcenter2></Callcenter2>}></Route>
                    <Route path="/callcenter/:id" element={<CallcenterDetail></CallcenterDetail>}></Route>
                    <Route path="/mycall" element={<Mycall></Mycall>}></Route>
                    <Route path="/notice" element={<Notice/>}></Route>
                    <Route path="/notice/:id" element={<NoticeDetail/>}></Route>
                    <Route path="/notice/register" element={<NoticeWrite/>}></Route>
                    <Route path="/ranktrace" element={<RankTrace setIslogin={setIslogin}/>}/>
                    <Route path="/ranktrace/:id" element={<RankTraceDetail/>}/>
                    <Route path="/purchase" element={<PurchasePage/>}/>
                    <Route path="/purchase2" element={<PurchasePage2/>}/>
                    <Route path="/success" element={<SuccessPage/>}/>
                    <Route path="/fail" element={<FailPage/>}/>
                    <Route path="/checkout" element={<CheckoutPage/>}/>

                </Routes>
            </main>

            {/* 푸터 */}
            <Footer/>
        </div>
    );
}

export default App;
