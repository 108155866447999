import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import Searchbar from "./Searchbar";
import DataTable from "./DataTable";
import MobileDataTable from "./MobileDataTable";

const Home = ({ islogin,setIslogin }) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [keyword, setKeyword] = useState(""); // 키워드 상태
    const [store, setStore] = useState("");
    const [searchedData, setSearchedData] = useState([]);
    const location = useLocation(); // 키워드 읽기

    const fetchData = async () => {
        setLoading(true);
        try {
            const token = localStorage.getItem("jwt");
            if (!token) {
                alert("로그인된 상태가 아닙니다.");
                setLoading(false);
                return;
            }

            const countResponse = await axios.get(`https://weorthersback.shop/api/searchcount`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const { searchcount, maxcount } = countResponse.data;

            if (parseInt(searchcount) >= parseInt(maxcount)) {
                alert("검색 횟수가 최대치를 초과했습니다. 검색을 제한합니다.");
                setLoading(false);
                return;
            }

            setSearchedData([]);
            const response = await axios.get(`https://honbob.site:8443/api/${keyword}`); // 검색 요청
            if (response.data && response.data.result) {
                await axios.post('https://weorthersback.shop/increment', {}, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                setTimeout(() => {
                    setData(response.data);

                    if (store !== '') {
                        const data1 = response.data.result?.products || [];
                        const storeLower = store.toLowerCase();
                        const data2 = data1.filter(item =>
                            item.mallName?.toLowerCase().includes(storeLower) ||
                            item.productTitle?.toLowerCase().includes(storeLower)
                        );
                        setSearchedData(data2);
                        if (data2.length === 0) {
                            alert("검색한 스토어가 100위 밖입니다.");
                        }
                    }

                    setLoading(false);
                }, 500);
            } else {
                alert("검색 결과가 없습니다.");
                setLoading(false);
            }

        } catch (error) {
            console.error("Error fetching data:", error);
            setLoading(false);
        }
    };

    useEffect(() => {
        // SearchBarWithTypingEffect에서 전달된 키워드 읽기
        if (location.state?.keyword) {
            setKeyword(location.state.keyword);
        }
    }, [location.state]);

    useEffect(() => {
        // 키워드를 입력했을 경우 데이터 요청
        if (keyword) {
            fetchData();
        }
    }, [keyword, store]);

    return (
        <div>
            <Searchbar
                data={data}
                setData={setData}
                keyword={keyword}
                setKeyword={setKeyword}
                store={store}
                setStore={setStore}
                islogin={islogin}
                setIslogin={setIslogin}
            />
            <div>
                {loading && <p>로딩 중...</p>}
            </div>
            <div>
                <DataTable
                    data={data}
                    setData={setData}
                    loading={loading}
                    setLoading={setLoading}
                    searchedData={searchedData}
                    setSearchedData={setSearchedData}
                />
            </div>
        </div>
    );
};

export default Home;