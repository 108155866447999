import { useRef } from "react";
import { useNavigate } from 'react-router-dom';

import KakaoLoginButton2 from "./KakaoLoginButton2";
import axios from "axios";
import KakaoLogout from "./KakaoLogout";


const LoginPage = ({setIslogin}) => {
    const Id1 = useRef(null);
    const Pw1 = useRef(null);
    const navigate = useNavigate();

    const GoLogin = () => {
        const id1 = Id1.current.value
        const pw1 = Pw1.current.value
        const data1 = {
            userId : id1,
            userPw : pw1
        }
        console.log("보내는 data : ", data1);
        postData(data1);

    }
    const postData = async (data1) => {
        try {
            const response = await axios.post('https://weorthersback.shop/users/login', data1, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            // 성공적인 응답 처리
            console.log('응답 데이터:', response.data);

            // 세션에 사용자 정보 저장
            alert(`반갑습니다 ${response.data.userName} 님`);

            // JWT 토큰을 로컬스토리지에 저장
            localStorage.setItem("jwt", response.data.token);

            setIslogin(true);
            // 페이지 이동
            navigate("/");

        } catch (error) {
            // 에러가 발생했을 때 처리
            console.error('요청 실패:', error);
            // 에러 응답에서 상태 코드 확인
            if (error.response) {
                console.error('에러 응답 데이터:', error.response.data);
                alert(`로그인 실패: ${error.response.data.message || "알 수 없는 오류 발생"}`);
            } else {
                alert("로그인 실패: 서버에 연결할 수 없습니다.");
            }
        }
    };


    return (
        <div className="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">

                <h2 className="mt-10 text-left text-blue-600 text-4xl/9 font-bold tracking-tight ">Login
                    </h2>
            </div>

            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                <form className="space-y-6" method="POST">
                    <div>
                        <label htmlFor="id" className="block text-sm/6 font-medium text-gray-900 text-left">ID</label>
                        <div className="mt-2">
                            <input id="email" name="id" type="text" autoComplete="email" required
                                   ref={Id1}
                                   className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"/>
                        </div>
                    </div>

                    <div>
                        <div className="flex items-center justify-between">
                            <label htmlFor="password"
                                   className="block text-sm/6 font-medium text-gray-900">Password</label>
                            {/*<div className="text-sm">*/}
                            {/*    <a href="#" className="font-semibold text-blue-600 hover:text-indigo-500">비밀번호 찾기</a>*/}
                            {/*</div>*/}
                        </div>
                        <div className="mt-2">
                            <input id="password" name="password" type="password" autoComplete="current-password"
                                   required
                                   ref={Pw1}
                                   className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"/>
                        </div>
                    </div>


                </form>
                <div className="py-6">
                    <button type="submit"
                            onClick={() => {
                                GoLogin()
                            }}
                            className="flex w-full justify-center rounded-md bg-blue-600 px-3 py-1.5 text-sm/6 font-semibold text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Sign
                        in
                    </button>
                </div>
                <div className="justify-center mt-10">
                    <KakaoLoginButton2></KakaoLoginButton2>

                </div>
                {/*<p className="mt-10 text-center text-sm/6 text-gray-500">*/}
                {/*    아직 회원이 아니신가요 ?*/}
                {/*    <button onClick={() => {*/}
                {/*        navigate('/signup')*/}
                {/*    }} className="font-semibold text-blue-600 hover:text-indigo-500 pl-4">회원가입 하러 가기</button>*/}
                {/*</p>*/}
            </div>
        </div>
    );
};

export default LoginPage;
