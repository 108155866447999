import React, { useEffect, useState, useRef } from "react";
import SearchBarWithTypingEffect from "./SearchBarWithTypingEffect";
import TypingText from "./TypingText";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css"; // Swiper 기본 스타일
import "swiper/css/effect-coverflow"; // 3D Coverflow 효과 스타일
import "swiper/css/pagination"; // Pagination 스타일
import {EffectCoverflow, Navigation, Pagination} from "swiper/modules";
import 'swiper/css/navigation';
import Emailform from "./Emailform";    // Navigation 모듈 스타일
const HomePage = () => {
    const [currentSection, setCurrentSection] = useState(0); // 현재 활성화된 섹션 상태
    const [rotateText, setRotateText] = useState(""); // 타이핑 텍스트 상태
    const sectionsRef = useRef([]); // 각 섹션의 DOM 참조
    const rotateContent = ["화환", "사탕", "장화", "강아지옷", "볼펜"]; // 타이핑 효과 내용

    // 타이핑 효과 로직
    useEffect(() => {
        let textIndex = 0;

        const typeText = () => {
            setRotateText(rotateContent[textIndex]);
            textIndex = (textIndex + 1) % rotateContent.length; // 순환
        };

        typeText();
        const typingInterval = setInterval(typeText, 3000); // 3초 간격으로 텍스트 변경

        return () => clearInterval(typingInterval); // 언마운트 시 정리
    }, []);

    // Full Page 스크롤 로직
    useEffect(() => {
        const handleScroll = (e) => {
            e.preventDefault();

            let direction = e.deltaY > 0 ? 1 : -1; // 스크롤 방향
            setCurrentSection((prev) => {
                const nextSection = Math.max(-1, Math.min(sectionsRef.current.length - 1, prev + direction));

                // 첫 번째 섹션 위로 스크롤 시 nav로 이동
                if (nextSection === -1) {
                    window.scrollTo({ top: 0, behavior: "smooth" });
                    return -1;
                }

                // 다른 섹션으로 이동
                sectionsRef.current[nextSection]?.scrollIntoView({ behavior: "smooth" });
                return nextSection;
            });
        };

        window.addEventListener("wheel", handleScroll, { passive: false });

        return () => window.removeEventListener("wheel", handleScroll); // 언마운트 시 리스너 제거
    }, []);

    return (
        <div className="w-full overflow-hidden">
            {/* 인디케이터 */}
            <div className="fixed bottom-2 left-1/2 -translate-x-1/2 flex gap-2">
                {Array(3)
                    .fill(0)
                    .map((_, index) => (
                        <div
                            key={index}
                            className={`w-3 h-3 rounded-full ${
                                currentSection === index ? "bg-black" : "bg-gray-500"
                            }`}
                        />
                    ))}
            </div>

            {/* 첫 번째 섹션 */}
            <div
                ref={(el) => (sectionsRef.current[0] = el)}
                className="h-[111vh] bg-cover bg-center flex justify-center items-center flex-col text-center px-5 relative pb-[10%]"

            >
                {/* 배경의 그라데이션 오버레이 */}
                {/*<div className="absolute inset-0 bg-gradient-to-t from-black via-transparent to-transparent"></div>*/}

                {/* 콘텐츠 영역 */}
                <div className="relative z-10 flex">
                    <h1
                        className="text-4xl md:text-5xl font-bold mb-5 text-yellow-400"
                        style={{
                            textShadow: "1px 1px 1px rgba(0, 0, 0, 0.8)", // 더 진하고 선명한 테두리 효과
                        }}
                    >
                        상품 검색
                    </h1>
                    <h1 className="text-4xl md:text-5xl font-bold mb-5 text-black">
                        의 새 기준!
                        더 빠르고, 정확하게.
                    </h1>
                </div>

                <p className="text-lg md:text-2xl mb-10 max-w-3xl leading-relaxed text-black">
                    <strong>지수 검색</strong>, <strong>네이버 플러스 스토어 검색</strong>, <strong>검색 랭킹 추적</strong>까지 한 곳에서! <br
                    className="hidden md:block"/>

                </p>
                <TypingText></TypingText>
                <SearchBarWithTypingEffect></SearchBarWithTypingEffect>
                <ul className="list-none p-0 max-w-2xl text-left text-lg leading-relaxed text-black mt-12">
                    <li>🔍 <strong>지수 검색</strong>: 한 번의 검색으로 수백만 개의 상품을 탐색하세요.</li>
                    <li>🏬 <strong>네이버 플러스 스토어 검색</strong>: 최신 스토어 상품을 빠르게 확인.</li>
                    <li>📈 <strong>검색 랭킹 추적</strong>: 관심 상품의 랭킹을 실시간으로 추적하세요.</li>
                </ul>

            </div>



            {/* 두 번째 섹션 */}
            <div
                ref={(el) => (sectionsRef.current[1] = el)}
                className="h-[111vh] flex flex-col justify-center items-center text-black px-5 text-center relative"
            >
                {/* 위에서 아래로 옅어지는 검은 그라데이션 배경 */}
                {/*<div className="absolute inset-0 bg-gradient-to-b from-black via-transparent to-transparent"></div>*/}

                <h1 className="text-2xl md:text-4xl font-bold mb-8 text-shadow-lg z-10">
                    사용법 안내
                </h1>

                {/* Swiper 캐러셀 */}
                <Swiper
                    navigation={true}
                    modules={[Navigation]}
                    allowTouchMove={false} // 드래그 넘기기 비활성화
                    className="w-[80%] h-[90vh] z-10"
                >
                    {/* 첫 번째 슬라이드 */}
                    <SwiperSlide>
                        <div className="p-5 bg-neutral-700 text-white rounded-lg flex items-center shadow-lg sw-full h-full box-border">
                            {/* 왼쪽: 이미지 */}
                            <div className="w-1/2 p-4">
                                <img
                                    src="/assets/지수검색/search1.png"
                                    alt="📌 지수 검색"
                                    className="w-full h-auto rounded-lg"
                                />
                            </div>
                            {/* 오른쪽: 텍스트 영역 */}
                            <div className="w-1/2 p-4 flex flex-col justify-center text-left">
                                <h2 className="text-xl font-bold mb-4">📌 지수 검색</h2>
                                <ul className="list-disc list-inside text-base mb-5">
                                    <li>효율적인 상품 탐색을 원하시나요? 지수 검색 기능으로 상품을 빠르고 정확하게 검색해보세요.</li>
                                    <li>검색 키워드만으로도 검색 가능하며 상위 100개의 상품의 데이터를 자세하게 확인 가능합니다.</li>
                                    <li>랭킹순/판매순/클릭순/가격순/품질순 등 옵션을 변경하여 한 눈에 데이터를 볼 수 있습니다.</li>
                                    <li>엑셀 저장 버튼을 눌러 현재 정렬된 검색 결과를 엑셀 파일로 다운로드 받을 수 있습니다.</li>
                                    <li>이때 파일명은 검색 키워드로 저장됩니다.</li>
                                </ul>
                                <div className="text-end mb-5 pr-12">
                                    <a
                                        href="https://www.xn--9m1bk40at2bg7b.shop/search"
                                        rel="noopener noreferrer"
                                        className="w-20 px-4 py-2 bg-blue-500 text-white rounded-lg text-lg shadow text-center"
                                    >
                                        이동
                                    </a>
                                </div>

                            </div>
                        </div>
                    </SwiperSlide>

                    {/* 두 번째 슬라이드 */}
                    <SwiperSlide>
                        <div
                            className="p-5 bg-neutral-700 text-white rounded-lg flex items-center shadow-lg w-full h-full box-border">
                        {/* 왼쪽: 이미지 */}
                            <div className="w-1/2 p-4">
                                <img
                                    src="/assets/네이버플러스스토어/naversearch1.png"
                                    alt="📌 네이버 플러스 순위 검색"
                                    className="w-full h-auto rounded-lg"
                                />
                                <img
                                    src="/assets/네이버플러스스토어/naversearch2.png"
                                    alt="📌 네이버 플러스 순위 검색"
                                    className="w-full h-auto rounded-lg"
                                />
                            </div>
                            {/* 오른쪽: 텍스트 영역 */}
                            <div className="w-1/2 p-4 flex flex-col justify-center text-left">
                                <h2 className="text-xl font-bold mb-4">📌 네이버 플러스 순위 검색</h2>
                                <ul className="list-disc list-inside text-base mb-5">
                                    <li>효율적인 상품 탐색을 원하시나요? 지수 검색 기능으로 상품을 빠르고 정확하게 검색해보세요.</li>
                                    <li>검색 키워드만으로도 검색 가능하며 상위 100개의 상품의 데이터를 자세하게 확인 가능합니다.</li>
                                    <li>랭킹순/판매순/클릭순/가격순/품질순 등 옵션을 변경하여 한 눈에 데이터를 볼 수 있습니다.</li>
                                    <li>엑셀 저장 버튼을 눌러 현재 정렬된 검색 결과를 엑셀 파일로 다운로드 받을 수 있습니다.</li>
                                    <li>이때 파일명은 검색 키워드로 저장됩니다.</li>
                                </ul>
                                <div className="text-end mb-5 pr-12">
                                    <a
                                        href="https://www.xn--9m1bk40at2bg7b.shop/naversearch1"
                                        rel="noopener noreferrer"
                                        className="w-20 px-4 py-2 bg-blue-500 text-white rounded-lg text-lg shadow text-center"
                                    >
                                        이동
                                    </a>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>

                    {/* 세 번째 슬라이드 */}
                    <SwiperSlide>
                        <div
                            className="p-5 bg-neutral-700 text-white rounded-lg flex items-center shadow-lg w-full h-full box-border">
                            {/* 왼쪽: 서브 슬라이드로 표현된 이미지 섹션 */}
                            <div className="w-1/2 p-4">
                                <img
                                    src="/assets/순위추적/ranktrace3.png"
                                    alt="📌 네이버 플러스 순위 검색"
                                    className="w-full h-auto rounded-lg"
                                />
                                <img
                                    src="/assets/순위추적/ranktrace1.png"
                                    alt="📌 네이버 플러스 순위 검색"
                                    className="w-full h-auto rounded-lg"
                                />
                            </div>
                            {/* 오른쪽: 텍스트 영역 */}
                            <div className="w-1/2 p-4 flex flex-col justify-center text-left">
                                <h2 className="text-xl font-bold mb-4">📌 네이버 순위 추적</h2>
                                <p className="text-base mb-5 whitespace-pre-line">
                                    1. 상단 네이게이션 바에서 네이버 쇼핑-랭킹 추적을 눌러주세요. <br/>
                                    2. 키워드와 nvmid를 입력해 검색 후 랭킹 추적 버튼을 눌러주세요. <br/>
                                    3. 리스트에 추가된 항목을 누르면 상세 페이지로 이동됩니다. <br/>
                                    매일 오전 11시에 자동 검색되어 db에 저장되며, 회원 등급에 따라 슬롯 갯수는 다를 수 있습니다.
                                </p>
                                <div className="text-end mb-5 pr-12">
                                    <a
                                        href="https://www.xn--9m1bk40at2bg7b.shop/ranktrace"
                                        rel="noopener noreferrer"
                                        className="w-20 px-4 py-2 bg-blue-500 text-white rounded-lg text-lg shadow text-center"
                                    >
                                        이동
                                    </a>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </div>

            {/* 세 번째 섹션 */}
            <div
                ref={(el) => (sectionsRef.current[2] = el)}
                className="h-[111vh] bg-cover bg-center   px-5"

            >
                <Emailform></Emailform>

            </div>
        </div>
    );
};

export default HomePage;