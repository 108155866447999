import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

export function SuccessPage() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        // 쿼리 파라미터 값이 결제 요청할 때 보낸 데이터와 동일한지 반드시 확인하세요.
        // 클라이언트에서 결제 금액을 조작하는 행위를 방지할 수 있습니다.
        const requestData = {
            orderId: searchParams.get("orderId"),
            amount: searchParams.get("amount"),
            paymentKey: searchParams.get("paymentKey"),

        };

        async function confirm() {
            const token = localStorage.getItem('jwt');
            const response = await fetch("https://weorthersback.shop/confirm", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(requestData),
            });

            const json = await response.json();
            console.log(json)
            // navigate("/");
            if (!response.ok) {
                // 결제 실패 비즈니스 로직을 구현하세요.
                console.log(json.message);
                // navigate(`/fail?message=${json.message}&code=${json.code}`);
                return;
            }

            // 결제 성공 비즈니스 로직을 구현하세요.
        }
        confirm();
    }, []);

    return (
        <div className="result wrapper flex justify-center items-center min-h-screen bg-gray-50">
            <div className="box_section bg-white shadow-lg rounded-lg p-8 text-center">
                <h2 className="text-4xl md:text-5xl font-bold text-blue-500 mb-6">
                    🎉 결제 성공! 🎉
                </h2>
                <p className="text-lg md:text-xl font-medium mb-2 text-gray-700">{`주문번호: ${searchParams.get("orderId")}`}</p>
                <p className="text-lg md:text-xl font-medium mb-2 text-gray-700">{`결제 금액: ${Number(
                    searchParams.get("amount")
                ).toLocaleString()}원`}</p>


                {/* 홈으로 이동 버튼 */}
                <button
                    className="mt-6 px-6 py-3 bg-blue-500 hover:bg-blue-600 text-white font-bold rounded-lg shadow-md transition-all duration-200"
                    onClick={()=>{navigate("/")}} // 홈 URL로 이동
                >
                    홈으로 이동
                </button>
            </div>
        </div>
    );
}