import React, { useState, useEffect } from "react";

const TypingText = () => {
    const rotateContent = ["화환", "사탕", "장화", "강아지옷", "볼펜"]; // 타이핑 효과 내용
    const [displayedText, setDisplayedText] = useState(""); // 한 글자씩 표시될 텍스트
    const [isCursorVisible, setIsCursorVisible] = useState(true); // 깜빡이는 커서 유무
    const [textIndex, setTextIndex] = useState(0); // 현재 단어의 인덱스
    const [charIndex, setCharIndex] = useState(0); // 현재 단어의 글자 인덱스

    useEffect(() => {
        let typingTimer;

        // 한 글자씩 타이핑하는 부분
        if (charIndex < rotateContent[textIndex].length) {
            typingTimer = setTimeout(() => {
                setDisplayedText((prev) => prev + rotateContent[textIndex][charIndex]); // 한 글자씩 추가
                setCharIndex(charIndex + 1);
            }, 350); // 글자 타이핑 속도 (50ms)
        } else {
            // 단어 전체를 타이핑 후, 짧은 대기 후 다음 단어로 이동
            typingTimer = setTimeout(() => {
                setDisplayedText(""); // 텍스트 초기화
                setCharIndex(0); // 글자 인덱스 초기화
                setTextIndex((textIndex + 1) % rotateContent.length); // 다음 단어로 순환
            }, 500); // 300ms 대기
        }

        return () => clearTimeout(typingTimer); // 타이머 정리
    }, [charIndex, rotateContent, textIndex]);

    useEffect(() => {
        // 커서 깜빡이는 효과
        const cursorTimer = setInterval(() => {
            setIsCursorVisible((prev) => !prev);
        }, 500); // 500ms 간격으로 깜빡임

        return () => clearInterval(cursorTimer); // 커서 타이머 정리
    }, []);

    return (
        <p className="text-black text-3xl font-bold">
            지금 바로 검색창에 "
            <span className="text-yellow-400">
                {displayedText}
                <span
                    className="text-black"
                    style={{
                        visibility: isCursorVisible ? "visible" : "hidden",
                    }}
                >
                    |
                </span>
            </span>
            "을 검색해보세요!
        </p>
    );
};

export default TypingText;