import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import KakaoLogout from "./KakaoLogout";

const Navbar = ({ navType, setNavType, islogin, setIslogin, admin }) => {
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false); // '네이버 플러스 스토어' 드롭다운 상태
    const [isDropdownVisible, setIsDropdownVisible] = useState(false); // '지수검색' 드롭다운 상태
    const [isUserMenuOpen, setIsUserMenuOpen] = useState(false); // 유저 메뉴 슬라이드 상태
    const [userInfo, setUserInfo] = useState()
    const [userDTO, setUserDTO] = useState();
    useEffect(() => {
        const jwtToken = localStorage.getItem("jwt");
        if (jwtToken) {
            const user = localStorage.getItem("userinfo");
            if (user) {
                const parsedUser = JSON.parse(user);
                if (parsedUser?.properties && parsedUser?.kakao_account) {
                    setUserInfo(parsedUser); // userInfo 속성에 유효한 값 설정
                } else {
                    console.error("userInfo 데이터 구조가 올바르지 않습니다:", parsedUser);
                }
            }

            const userDTO1 = localStorage.getItem("userDTO");
            if (userDTO1) {
                const parsedDTO = JSON.parse(userDTO1);
                if (parsedDTO?.grade) {
                    setUserDTO(parsedDTO); // userDTO 속성에 유효한 값 설정
                } else {
                    console.error("userDTO 데이터 구조가 올바르지 않습니다:", parsedDTO);
                }
            }
        }
    }, [islogin, admin, navigate, setUserInfo, setUserDTO]);

    const toggleUserMenu = () => {
        setIsUserMenuOpen(!isUserMenuOpen);
    };

    const toggleDropdown = () => {
        setIsOpen(!isOpen); // '네이버 플러스 스토어' 드롭다운 열기/닫기
    };

    const toggleIndexDropdown = () => {
        setIsDropdownVisible(!isDropdownVisible); // '지수검색' 드롭다운 열기/닫기
    };

    const GoLogout = () => {
        setIslogin(false);
        localStorage.clear();
        navigate("/login");
    };

    const navItems = {
        A: {
            title: "네이버 쇼핑",
            options: [
                { label: "nvmid 기준", path: "/naversearch1", type: "B" },
                { label: "상품명/스토어명 기준", path: "/naversearch2", type: "C" },
            ],
        },
        B: {
            title: "네이버 쇼핑",
            options: [
                { label: "nvmid 기준", path: "/naversearch1", type: "B" },
                { label: "상품명/스토어명 기준", path: "/naversearch2", type: "C" },
            ],
        },
        C: {
            title: "네이버 쇼핑",
            options: [
                { label: "nvmid 기준", path: "/naversearch1", type: "B" },
                { label: "상품명/스토어명 기준", path: "/naversearch2", type: "C" },
            ],
        },
    };

    const currentNav = navItems[navType];

    return (
        <div>
            {currentNav && (
                <div className="flex bg-blue-500 justify-around items-center w-full space-x-3 py-2">
                    <div>
                        <span
                            className="text-white text-[36px] mb-3 hover:cursor-pointer flex pt-3"
                            onClick={() => {
                                navigate("/");
                            }}
                        >
                            더스 마케팅
                        </span>
                    </div>
                    <div className="flex space-x-12">
                        {/* 공지사항 */}
                        <div className="relative pt-3">
                            <button
                                onClick={() => {
                                    navigate("/notice");
                                }}
                                className="flex items-center space-x-2 hover:text-gray-300"
                            >
                                <span className="text-white text-[28px] mb-3 hover:cursor-pointer flex">
                                    공지사항
                                </span>
                            </button>
                        </div>

                        {/* 지수검색 드롭다운 */}
                        <div className="relative">
                            <h1
                                className="text-white text-[28px] mt-3 hover:cursor-pointer flex"
                                onClick={toggleIndexDropdown}
                            >
                                {currentNav.title}
                                <svg
                                    className="w-6 h-6 mt-2 ml-2"
                                    fill="none"
                                    stroke="white"
                                    strokeWidth="2"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path d="M19 9l-7 7-7-7"></path>
                                </svg>
                            </h1>
                            {isDropdownVisible && (
                                <div className="absolute right-0 mt-2 w-48 bg-white text-black rounded-md shadow-lg z-50">
                                    <span
                                        onClick={() => {
                                            navigate("/");
                                            setNavType("A");
                                            setIsDropdownVisible(false);
                                        }}
                                        className="block px-4 py-2 hover:bg-gray-100"
                                    >
                                        지수검색
                                    </span>
                                    <span
                                        onClick={() => {
                                            navigate("/searchnow");
                                            setNavType("A");
                                            setIsDropdownVisible(false);
                                        }}
                                        className="block px-4 py-2 hover:bg-gray-100"
                                    >
                                        실시간 순위(nvmid)
                                    </span>
                                    <span
                                        onClick={() => {
                                            navigate("/searchnow2");
                                            setNavType("A");
                                            setIsDropdownVisible(false);
                                        }}
                                        className="block px-4 py-2 hover:bg-gray-100"
                                    >
                                        실시간 순위(마켓명)
                                    </span>
                                    <span
                                        onClick={() => {
                                            navigate("/ranktrace");
                                            setNavType("A");
                                            setIsDropdownVisible(false);
                                        }}
                                        className="block px-4 py-2 hover:bg-gray-100"
                                    >
                                        랭킹추적
                                    </span>
                                </div>
                            )}
                        </div>

                        {/* 네이버 플러스 스토어 드롭다운 */}
                        <div className="relative pt-3">
                            <button
                                onClick={toggleDropdown}
                                className="flex items-center space-x-2 hover:text-gray-300"
                            >
                                <span className="text-white text-[28px] mb-3 hover:cursor-pointer flex">
                                    네이버 플러스 스토어
                                </span>
                                <svg
                                    className="w-6 h-6 mb-3"
                                    fill="none"
                                    stroke="white"
                                    strokeWidth="2"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path d="M19 9l-7 7-7-7"></path>
                                </svg>
                            </button>
                            {isOpen && (
                                <div className="absolute right-0 mt-2 w-48 bg-white text-black rounded-md shadow-lg z-50">
                                    {currentNav.options.map((option, index) => (
                                        <span
                                            key={index}
                                            onClick={() => {
                                                navigate(option.path);
                                                setNavType(option.type);
                                                setIsOpen(false);
                                            }}
                                            className="block px-4 py-2 hover:bg-gray-100"
                                        >
                                            {option.label}
                                        </span>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                    <div>

                        {/* 유저 메뉴 */}
                        {islogin ? (
                            <div
                                className="relative flex items-center space-x-2 hover:cursor-pointer"
                                onClick={toggleUserMenu}
                            >
                                <img
                                    src={`${process.env.PUBLIC_URL}/assets/유저 아이콘.png`}
                                    className="h-12"
                                    alt="유저 아이콘"
                                />
                                <p className="text-white">유저</p>
                            </div>
                        ) : (
                            <div
                                className="hover:cursor-pointer"
                                onClick={() => {
                                    navigate("/login");
                                    // setIslogin(true);
                                }}
                            >
                                <div className="text-left pl-12">
                                    <img
                                        src={`${process.env.PUBLIC_URL}/assets/로그인아이콘.png`}
                                        className="h-12"
                                    />
                                    <p className="text-white">로그인</p>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}

            {/* 서브 네비게이션 (오른쪽 슬라이드) */}
            {/* 오른쪽 슬라이드 네브바 */}
            <div
                className={` fixed top-0 right-0 h-full w-80 bg-white shadow-lg z-50 transform ${
                    isUserMenuOpen ? "translate-x-0" : "translate-x-full"
                } transition-transform duration-300`}
            >
                <div className="flex flex-col p-4">

                    <div className="relative mx-auto w-24 h-24 mb-4 space-y-6">
                        {/* 프로필 이미지 */}
                        <div
                            className="w-full h-full rounded-full overflow-hidden relative border-4 border-gray-300 shadow-md">
                            {userInfo?.properties?.thumbnail_image ? (
                                <img
                                    src={userInfo.properties.thumbnail_image} // 여기에 실제 프로필 이미지 URL을 넣으세요
                                    alt="프로필 이미지"
                                    className="w-full h-full object-cover"
                                />
                            ) : (
                                <img
                                    src="https://via.placeholder.com/100" // 기본 프로필 이미지
                                    alt="기본 프로필 이미지"
                                    className="w-full h-full object-cover"
                                />
                            )}
                        </div>
                        <div>
                            {userInfo?.kakao_account?.name ? (
                                <p>{userInfo.kakao_account.name}</p>
                            ) : (
                                <p>이름없음</p>
                            )}
                        </div>
                        <div>
                            <div className="">
                                {
                                    userDTO ?
                                        <p
                                            className={`text-sm px-2 py-1 rounded-lg ${
                                                userDTO.grade === "1"
                                                    ? "bg-green-100 text-green-800"         // 베이직(무료)
                                                    : userDTO.grade === "2"
                                                        ? "bg-blue-100 text-blue-800"           // 스탠다드
                                                        : userDTO.grade === "3"
                                                            ? "bg-yellow-100 text-yellow-800"       // 프로
                                                            : userDTO.grade === "4"
                                                                ? "bg-orange-100 text-orange-800"       // 프리미엄
                                                                : userDTO.grade === "5"
                                                                    ? "bg-red-100 text-red-800"             // 마스터
                                                                    : userDTO.grade === "6"
                                                                        ? "bg-purple-100 text-purple-800"       // 관리자
                                                                        : "bg-gray-100 text-gray-800"           // 기본
                                            }`}
                                        >
                                            {userDTO.grade === "1"
                                            ? "베이직(무료)"
                                            : userDTO.grade === "2"
                                                ? "스탠다드"
                                                : userDTO.grade === "3"
                                                    ? "프로"
                                                    : userDTO.grade === "4"
                                                        ? "프리미엄"
                                                        : userDTO.grade === "5"
                                                            ? "마스터"
                                                            : userDTO.grade === "6"
                                                                ? "관리자"
                                                                : "알 수 없음"}
                                        </p>
                                        :
                                        <p
                                            className="text-sm px-2 py-1 rounded-lg
                                                bg-green-100 text-green-800"         // 베이직(무료)
                                        >
                                           베이직(무료)
                                        </p>
                                }

                            </div>
                        </div>

                    </div>


                    {/* 메뉴 섹션 */}
                    <span
                        className=" py-2 mt-36 hover:bg-gray-100 cursor-pointer"
                        onClick={() => {
                            setIsUserMenuOpen(false);
                            navigate("/mypage");
                        }}
                    >
                        내 정보
                    </span>
                    <span
                        className="py-2 hover:bg-gray-100 cursor-pointer"
                        onClick={() => {
                            setIsUserMenuOpen(false);
                            navigate("/mycall");
                        }}
                    >
                        문의하기
                    </span>

                    {localStorage.getItem("admin") === "true" && (
                        <span
                            className="py-2 hover:bg-gray-100 cursor-pointer"
                            onClick={() => {
                                setIsUserMenuOpen(false);
                                navigate("/adminpage");
                            }}
                        >
                            관리자 페이지
                        </span>
                    )}
                    {localStorage.getItem("admin") === "true" && (
                        <span
                            className="py-2 hover:bg-gray-100 cursor-pointer"
                            onClick={() => {
                                setIsUserMenuOpen(false);
                                navigate("/admin/callcenter");
                            }}
                        >
                            고객문의 응대
                        </span>
                    )}
                    <span
                        className="py-2 hover:bg-gray-100 cursor-pointer"
                        onClick={() => {
                            GoLogout();
                            setIsUserMenuOpen(false);
                        }}
                    >
                        <KakaoLogout/>
                    </span>
                </div>
            </div>

            {/* 배경 어두운 레이어 (서브 네비게이션 활성화 시) */}
            {isUserMenuOpen && (
                <div
                    className="fixed inset-0 bg-black bg-opacity-50 z-40"
                    onClick={toggleUserMenu}
                ></div>
            )}
        </div>
    );
};

export default Navbar;