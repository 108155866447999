import Button from "@mui/material/Button";
import { RiKakaoTalkFill } from 'react-icons/ri';

const KakaoLoginButton2 = () => {
    const REST_API_KEY = 'dcabad803dd970a060b75ad59756fc23';
    const REDIRECT_URI = "https://위아더스.shop/oauth/kakao";

    const kakaoURL = `https://kauth.kakao.com/oauth/authorize?client_id=${REST_API_KEY}&redirect_uri=${REDIRECT_URI}&response_type=code`;
    // 밑에 거 붙이면 무조건 id쳐서 로그인
    // &prompt=login
    // const handleKakaoLogin = () => {
    //     window.open(kakaoURL, "_blank", "width=600,height=800,top=100,left=100,resizable=yes,scrollbars=yes");
    //
    // };
    const handleKakaoLogin = () => {
        window.location.href = kakaoURL; // 현재 창에서 바로 이동
    };

    return (
        <div className="flex justify-center pt-2">
            <button
                variant="secondary"
                className="flex w-50 p-2 items-center justify-center gap-2 rounded-md bg-[#FEE500] text-sm font-medium text-gray-700 transition-colors duration-300 hover:border hover:border-yellow-300 hover:bg-[#FFEB3B]"
                onClick={handleKakaoLogin}

            >
                <RiKakaoTalkFill size={30}/>
                <p className=" text-sm p-2">카카오톡으로 로그인/회원가입</p>
            </button>

        </div>

    );
};

export default KakaoLoginButton2;