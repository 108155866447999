import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Emailform = () => {
    const [title, setTitle] = useState("");
    const [category, setCategory] = useState(""); // 카테고리 상태 추가
    const [editorValue, setEditorValue] = useState("");
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault(); // 기본 폼 제출 동작 방지

        // 카테고리가 선택되지 않았다면 경고 후 종료
        if (category === "") {
            alert("카테고리를 선택해 주세요");
            return;
        }

        const token = localStorage.getItem("jwt"); // JWT 토큰 가져오기

        if (!token) {
            alert("로그인된 상태가 아닙니다.");
            navigate("/login");
            return;
        }

        // 요청 데이터
        const data = {
            title: title,
            category: category,
            content: editorValue,
        };

        try {
            console.log(data);

            // API 요청
            const response = await axios.post(
                "https://weorthersback.shop/callcenter",
                data,
                {
                    headers: {
                        Authorization: `Bearer ${token}`, // 토큰 추가
                    },
                }
            );

            console.log("응답:", response.data);
            alert("게시물이 등록되었습니다.");
            navigate("/mycall"); // 폼 제출 후 페이지 이동
        } catch (error) {
            // 서버에서 반환한 에러 메시지 확인
            if (error.response) {
                console.error("서버 응답 오류:", error.response.data);
                alert(`서버 오류: ${error.response.data}`); // 서버에서 반환한 오류 메시지
            } else if (error.request) {
                console.error("요청 오류:", error.request);
                alert("서버 응답을 받지 못했습니다. 나중에 다시 시도해주세요.");
            } else {
                console.error("오류 발생:", error.message);
                alert("알 수 없는 오류가 발생했습니다.");
            }
        }
    };

    return (
        <div className="flex justify-around bg-center items-center px-5 pt-48">
            {/* 왼쪽 안내 문구 */}
            <div className="flex-1 text-black p-8 flex flex-col justify-center items-start max-w-2xl text-left">
                <h2 className="text-4xl font-bold mb-5">무엇을 도와드릴까요?</h2>
                <p className="leading-loose text-2xl">
                    언제든지 편하게 문의해주세요. <br />
                    저희는 고객님의 성공을 돕기 위해 최선을 다하겠습니다. <br />
                    궁금한 내용은 오른쪽 폼을 통해 보내주세요!
                </p>
            </div>

            {/* 오른쪽 폼 */}
            <div className="flex-1 bg-white/90 p-10 rounded-lg shadow-lg max-w-lg w-full">
                <h2 className="text-blue-500 text-3xl font-bold mb-8 text-left">문의하기</h2>
                <form onSubmit={handleSubmit}>
                    {/* 제목 */}
                    <div className="mb-5 text-left">
                        <label htmlFor="title" className="block font-bold mb-1 text-gray-800">
                            제목
                        </label>
                        <input
                            type="text"
                            id="title"
                            name="title"
                            placeholder="문의 제목을 입력해주세요"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            className="w-full px-3 py-2 rounded border border-gray-300 outline-none text-base"
                            required
                        />
                    </div>

                    {/* 카테고리 */}
                    <div className="mb-5 text-left">
                        <label htmlFor="category" className="block font-bold mb-1 text-gray-800">
                            카테고리
                        </label>
                        <select
                            id="category"
                            name="category"
                            value={category}
                            onChange={(e) => setCategory(e.target.value)}
                            className="w-full px-3 py-2 rounded border border-gray-300 outline-none text-base"
                            required
                        >
                            <option>카테고리를 선택해주세요</option>
                            <option value="단순문의">단순문의</option>
                            <option value="사업문의">사업문의</option>
                            <option value="오류">오류</option>
                            <option value="회원가입/등급 문의">회원가입/등급 문의</option>
                            <option value="기타">기타</option>
                        </select>
                    </div>

                    {/* 문의내용 */}
                    <div className="mb-6 text-left">
                        <label htmlFor="editorValue" className="block font-bold mb-1 text-gray-800">
                            문의내용
                        </label>
                        <textarea
                            id="editorValue"
                            name="editorValue"
                            rows="5"
                            placeholder="문의 내용을 입력해주세요. 회사명, 전화번호 등을 적어주시면 답변이 더 빨라집니다."
                            value={editorValue}
                            onChange={(e) => setEditorValue(e.target.value)}
                            className="w-full px-3 py-2 rounded border border-gray-300 outline-none text-base"
                            required
                        />
                    </div>

                    {/* 제출 버튼 */}
                    <button
                        type="submit"
                        className="w-full py-3 text-white bg-blue-500 rounded shadow-lg font-bold text-lg"
                    >
                        제출하기
                    </button>
                </form>
            </div>
        </div>
    );
};

export default Emailform;