
import {useState} from "react";
import PaymentCheckoutPage from "./PaymentCheckoutPage";

const PurchasePage2 = () => {
    const [modal, setModal] = useState(false);
    return(
        <div className="flex justify-center">
            <div className="w-[50%]">

                <PaymentCheckoutPage></PaymentCheckoutPage>

            </div>
        </div>

    )
}
export default PurchasePage2;